import Flatpickr from 'stimulus-flatpickr';

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  change(_selectedDates, _dateStr, instance) {
    if (!instance.input.hasAttribute('data-clear-error-on-change')) {
      return;
    }

    const closestDiv = instance.input.closest("div");
    const errorBox = closestDiv.querySelector(".form-hint.error");

    if (!errorBox) {
      return;
    }

    errorBox.remove();
    closestDiv.querySelector(".input.error").classList.remove("error");
  }
}